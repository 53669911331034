import React from "react";
import '../styles/topnav.css';
import {
  Switch,
  Route,
  Link
} from "react-router-dom";

function TopNav () {
  return (
    <section>
      <div class="container logo-bar">
        <div class="columns">
          <div class="column is-hidden-mobile">
          </div>
          <div class="column left-logo-column">
            <Link to='/' alt='Start a new GYFT search'>
              <div class='logo' title='GYFTEO Logo'></div>
            </Link>
          </div>
          {/* <div class="column is-three-fifths is-hidden-mobile"> */}
          <div class="column is-three-fifths title-column">
            <Switch>
              <Route path="/gyfts">
                <h1 class="small-title">
                  Find A Great Gift
                </h1>
                <div class='scarlet-highlight-line'></div>
              </Route>
            </Switch>
          </div>
          <div class="column">
          </div>
          <div class="column is-hidden-mobile">
            <div class='right-logo is-hidden'>
              <Link to='/' alt='Start a new GYFT search'>
                <div class='logo' tite='GYFTEO Logo'></div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopNav;