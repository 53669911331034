import React from "react";

function LoadingAction () {
  return (

    // Loading Results Modal
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box has-text-centered">
          <p>
            <strong>Curating GYFTs ...</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoadingAction;