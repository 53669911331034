import React from "react";

function ResultImage (props) {
  return (
    // Result Image
    <div class="card-image">
      <figure class="image is-4by3">
        <img src={props.imageUrl} alt={props.altText} />
      </figure>
    </div>
  );
};

export default ResultImage;