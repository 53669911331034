import React from "react";

function Questions (props) {
  return (
    // Questions Section
    <section class='section-questions'>
      <div class="container">
        <div class="columns">
          
          <div class="column">
          </div>

          <div class="column is-four-fifths">
            <span class="question-text">
              On a Wednesday evening, you'll 
              <span class="prevent-break">
                &nbsp;find <span class='is-capitalized'>{props.gyfteoName}</span>
                <select name="" class="question-one-select">
                  <option name=' '>Select</option>
                  {props.answersOne.map((answer, index) => (
                    <option name={answer.key} key={answer.key}>{answer.answer}</option>
                  ))}
                </select>
                .
              </span>
            </span>
            

            <span class="question-text">
              By Saturday afternoon, <span class='is-capitalized'>{props.gyfteoName}</span>
              <span class="prevent-break">
                &nbsp;will be
                <select name="" class="question-two-select">
                  <option name=''>Select</option>
                  {props.answersTwo.map((answer, index) => (
                    <option name={answer.key} key={answer.key}>{answer.answer}</option>
                  ))}
                </select>
                .
              </span>
            </span>

            <span class="question-text">
              When a <span class="prevent-break">long weekend</span> rolls around, <span class='is-capitalized'>{props.gyfteoName}</span>
              <span class="prevent-break">
                &nbsp;will be
                <select name="" class="question-three-select">
                  <option name=''>Select</option>
                  {props.answersThree.map((answer, index) => (
                    <option name={answer.key} key={answer.key}>{answer.answer}</option>
                  ))}
                </select>
                .
              </span>
            </span>

          </div>

          <div class="column">
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default Questions;