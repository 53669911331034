import React, { Component } from "react";
import Home from "./components/home";
import Search from "./components/search";
import Gyfts from "./components/gyfts";
import About from "./components/about";
// import LinkCreator from "./components/linkcreator";

import Results from "./components/results";
import ResultsEmpty from "./components/resultsempty";
import Footer from "./components/footer";
import LoadingAction from "./components/loadingaction"
import lookupResults from "./functions/lookupresults.js"
import lookupAnswers from "./functions/lookupanswers.js"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";



class App extends Component {
  constructor() {
    super();
    this.state = {
      gyfteoName: '',
      searchResults: {},
      searchResultsFoundNoGyfts: false,
      loadingResults: false,
      answers: {
        question_one: [],
        question_two: [],
        question_three: [],
      }
    };

    // Bind to the KeyUp function
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  // Initially look up answers
  componentDidMount() {
    lookupAnswers(this.setState.bind(this));

    document.addEventListener("keyup", this.onKeyUp);
  }

  // Listen for enter key and move to the correct next step
  // TODO - fix this
  onKeyUp(event) {
    if (event.keyCode === 13) {
      switch(this.state.currentStep) {
        case 1:
          let name =  document.getElementsByClassName('gyfteo-name')[0].value;
          if (name) {
            // this.loadNextStep();
          } else {
            // alert('please enter a name');
          }
          break;
        case 2:
          // this.handleSearchChange();
          break;
        case 3:
      }
    }
  }

  handleNameChange() {
    let new_gyfteo_name = document.getElementsByClassName('searching-for-input')[0].value

    this.setState({
      gyfteoName: new_gyfteo_name
    });
  }

  handleGoClick() {
    lookupResults(this.setState.bind(this));
  }


  render() {

    // Conditional statement for which version of the results to show
    const searchResultsFoundNoGyfts = this.state.searchResultsFoundNoGyfts;
    let resultsDisplay;
    if (searchResultsFoundNoGyfts) {
      resultsDisplay = <ResultsEmpty
      backToSearchOnClick={() => this.backToSearchOnClick()}
      />;
    } else {
      resultsDisplay = <Results
        gyfteoName={this.state.gyfteoName}
        searchResults={this.state.searchResults}
        backToSearchOnClick={() => this.backToSearchOnClick()}
      />;
    }

    // Conditional statement for showing the loading results component
    const loadingResults = this.state.loadingResults;
    let loadingActionDisplay;
    if (loadingResults) {
      loadingActionDisplay = <LoadingAction />;
    }



    return (

      <Router>
      <div class='body-wrapper'>

        <div class='wrapper'>

          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
          <Route path="/about">
              <About />
            </Route>
            <Route path="/gyfts">
              <Gyfts
                gyfteoName={this.state.gyfteoName}
                resultsDisplay={resultsDisplay}
              />
            </Route>
            <Route path="/search">
              <Search
                gyfteoName={this.state.gyfteoName}
                answersOne={this.state.answers.question_one}
                answersTwo={this.state.answers.question_two}
                answersThree={this.state.answers.question_three}
                handleGoClick={() => this.handleGoClick()}
              />
            </Route>
            {/* <Route path="/link-creator/:auth">
              <LinkCreator />
            </Route> */}
            <Route path="/">
              <Home
                nameTitleOnNameChange={() => this.handleNameChange()}
                gyfteoName={this.state.gyfteoName}
              />
            </Route>
          </Switch>
          {loadingActionDisplay}
        </div>
        <Footer />
      </div>
    </Router>
    );
  }
}


export default App;