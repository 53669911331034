import React from "react";
import '../styles/home.css';
import TopNav from "./topnav";
import updateWrapperClass from "../functions/updatewrapperclass.js"
import {Link} from "react-router-dom";

function Home (props) {
  updateWrapperClass();

  return (
    <div>
      {<TopNav />}
      <div class='columns home-columns'>
          <div class='column is-hidden-900'>
            <div class='picture-wrapper'>
              <div class='picture-top'></div>
              <div class='picture-bottom'></div>
            </div>
          </div>
          
          <div class='column is-three-fifths'>
            <div class='columns'>
              <div class='column is-2 is-hidden-mobile'>
                <div class='subtext-tagline-holder'>
                  <div class='subtext-tagline' title='great GYFTS for great people'></div>
                </div>
              </div>
              <div class='column home-block'>
                  <h1>GYFTEO</h1>
                  <h2>Find A Great Gift</h2>
                  <p class='searhing-for-title'>Who are you searching for?</p>
                  <input class='searching-for-input' placeholder='Type Name' value={props.gyfteoName}  autoComplete='off' autoFocus onChange={props.nameTitleOnNameChange} />
                  <Link to="/search" title='Onward to the next step'>
                    <button class='home-next'>Next -></button>
                  </Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};



export default Home;