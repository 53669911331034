const updateWrapperClass = function(setState) {
  const path = window.location.pathname;
  const bodyWrapper = document.getElementsByClassName('body-wrapper')[0]

  if( bodyWrapper ){
    switch(path) {
      case '/gyfts':
        bodyWrapper.classList.add('white-background');
        break;
      case '/search':
        document.getElementsByClassName('body-wrapper')[0].classList.remove('white-background');
        break;
      default:
        document.getElementsByClassName('body-wrapper')[0].classList.remove('white-background');
    }
  }
}

export default updateWrapperClass;