import React from "react";
import ResultImage from "../components/resultimage";

function Result (props) {

  let resultImage;
  let resultImageUrl;
  let altText = props.result.primaryImageAltText;

  const basePrice = formatBasePrice(props?.result?.basePrice);

  let productDescription = props.result.descriptionFull ? props.result.descriptionFull : props.result.description;

  if( props.result.primaryImage ){
    resultImageUrl = props.result.primaryImage.url;
  } else if ( props.result.primaryImageUrl ) {
    resultImageUrl = props.result.primaryImageUrl;
  } else {
    resultImageUrl = "https://bulma.io/images/placeholders/1280x960.png";
  }


  resultImage = <ResultImage imageUrl={resultImageUrl} altText={altText} />;

  function addAffiliateID (urlString) {
    var newUrlString = urlString;

    const expression = /\amazon\b/g;
    const isAmazon = expression.test(urlString);
    if( isAmazon ) {

      const expression = /\gyfteo-20\b/g;
      const hasAssociateTag = expression.test(urlString);
      if( !hasAssociateTag ){
        // Add the Amazon Associate Tag and remove double //
        newUrlString = (urlString + "/ref=nosim?tag=gyfteo-20").replace("//ref", "/ref");
      }

    }

    return newUrlString;
  }

  return (
    // Result
    <div class="card card-equal-height">

      {resultImage}

      <div class="card-content">

        <div class="media">
          <div class="media-content">
            <p class="title is-4">{props.result.title}</p>
          </div>
        </div>

        <div class="content">
          <p>{productDescription}</p>
        </div>

      </div>

      <footer class="card-footer">
        <p class="card-footer-item">
          <span>
            {basePrice}
          </span>
        </p>
        <a href={addAffiliateID(props.result.baseUrl)} class="gyft-it card-footer-item" target='_blank' rel="noreferrer">
          <span>
            {/* Get the GYFT -{'>'} */}
            Get the GYFT
          </span>
        </a>
      </footer>

    </div>
  );
};

export default Result;


function formatBasePrice(number) {
  // let basePrice = props.result.basePrice ? "$" + props.result.basePrice.toFixed(2) : 'Price Unknown';
  let price = '$';

  if( number > 35 && number <= 90 ) {
    price = '$$';
  } else if( number > 90 ) {
    price = '$$$';
  }

  return price;
}