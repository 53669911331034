import axios from 'axios';

let contentful_space_id = process.env.REACT_APP_SPACE_ID;
let contentful_access_token = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
let contentful_url = 'https://graphql.contentful.com/content/v1/spaces/' + contentful_space_id;

const lookupResults = function(setState) {
  let question_one = document.getElementsByClassName('question-one-select')[0];
  let question_one_values = Array.from(question_one.selectedOptions).map(option => option.value);

  let question_two = document.getElementsByClassName('question-two-select')[0];
  let question_two_values = Array.from(question_two.selectedOptions).map(option => option.value);

  let question_three = document.getElementsByClassName('question-three-select')[0];
  let question_three_values = Array.from(question_three.selectedOptions).map(option => option.value);

  let answer_names = question_one_values.concat(question_two_values).concat(question_three_values);

  // TODO - ensure they picked an answer to all three questions

  // Start the sequence
  getCategories(setState, answer_names);
}


const getCategories = function(setState, answer_names) {

  // Load the loading action
  startLoadingAction(setState);

  let category_names = [];
  let unique_answer_names = [...new Set(answer_names)];
  let json_names = JSON.stringify(unique_answer_names);

   let graphql_query =  {
    query: `
      {
        answerCollection(where: {
          title_in: ${json_names}
        }) {
          items {
            title
            linkedFrom {
              categoryCollection {
                items {
                  name
                  sys {
                    id
                  }
                }
              }
            }
          }
        }
      }
    `
  }

  axios({
      url: contentful_url,
      method: 'post',
      headers: { Authorization: 'Bearer ' + contentful_access_token },
      data: graphql_query
    }).then((result) => {

      let answers = result.data.data.answerCollection.items;

      // Loop the answers
      for (var i = answers.length - 1; i >= 0; i--) {

        // Find the answer categories
        let this_answer_categories = answers[i].linkedFrom.categoryCollection.items;

        // Loop the answer categories and populate the category array
        for (var a = this_answer_categories.length - 1; a >= 0; a--) {
          category_names.push(this_answer_categories[a].name);
        };

      };

      // Call the next query and pass the categories
      getGyfts(setState,category_names);

    }).catch(error => {
      // TODO - handle showing an error
      console.log(error);
    });
}


const getGyfts = function(setState, category_names) {

  let gyfts = [];
  let unique_category_names = [...new Set(category_names)];
  let json_categories = JSON.stringify(unique_category_names);

  let graphql_query =  {
    query: `
      {
        categoryCollection(where: {
          name_in: ${json_categories}
        }) {
          items {
            name
            linkedFrom {
              gyftCollection(limit: 30) {
                items {
                  title
                  description
                  descriptionFull
                  basePrice
                  baseUrl
                  primaryImageUrl
                  primaryImageAltText
                  primaryImage {
                    url(transform: {width: 800, format: JPG_PROGRESSIVE})
                    description
                  }
                  sys {
                    id
                  }
                }
              }
            }
          }
        }
      }
    `
  }

  axios({
      url: contentful_url,
      method: 'post',
      headers: { Authorization: 'Bearer ' + contentful_access_token },
      data: graphql_query
    }).then((result) => {

      let categories = result.data.data.categoryCollection.items;

      // Loop the categories
      for (var i = categories.length - 1; i >= 0; i--) {

        // Find the category gyfts
        let this_category_gyfts = categories[i].linkedFrom.gyftCollection.items;

        // Loop the category gyfts and populate the gyfts array
        for (var g = this_category_gyfts.length - 1; g >= 0; g--) {
          gyfts.push(this_category_gyfts[g]);
        };

      };

      // Unload the loading action
      stopLoadingAction(setState);

      if ( gyfts.length ) {

        // Ensure GYFTs are unique
        const filteredGyfts = gyfts.reduce((gyft, current) => {
          const x = gyft.find(item => item.title === current.title);
          if (!x) {
            return gyft.concat([current]);
          } else {
            return gyft;
          }
        }, []);

        shuffleArray(filteredGyfts);

        // Limit first set of results to 9
        // Create second set of results for the rest
        const firstNineResults = filteredGyfts.splice(0, 9);
        const gyftResults = {
          firstNineResults: firstNineResults,
          remainingResults: filteredGyfts
        }

        setState({
          searchResults: gyftResults,
          searchResultsFoundNoGyfts: false
        });
      } else {
        setState({
          searchResultsFoundNoGyfts: true
        });
      }


    }).catch(error => {
      // TODO - handle showing an error
      console.log(error);
    });

}


const startLoadingAction = function(setState) {
  setState({
    loadingResults: true
  });
}


const stopLoadingAction = function(setState) {
  setTimeout(() => {
    setState({
      loadingResults: false
    });
  }, 400);
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
}

export default lookupResults;