import React from "react";
import '../styles/search.css';
import TopNav from "./topnav";
import PrimaryHeader from "./primaryheader";
import Questions from "./questions";
import GoButton from "./utilities/gobutton";
import updateWrapperClass from "../functions/updatewrapperclass.js"

function Search (props) {
  updateWrapperClass();
  
  return (
    <div>
      {<TopNav />}
      {<PrimaryHeader />}
      {<Questions
        gyfteoName={props.gyfteoName}
        answersOne={props.answersOne}
        answersTwo={props.answersTwo}
        answersThree={props.answersThree}
      />}
      {<GoButton
        onClick={props.handleGoClick}
      />}
    </div>
  );
};

export default Search;