import React from "react";
import '../styles/primaryheader.css';

function PrimaryHeader (props) {
  return (
    <section class='primary-header-section'>
      <div class="container">

        <div class="columns primary-title-block">
          <div class="column is-one-fifths is-hidden-mobile">
          </div>
          <div class="column is-three-fifths">
            <h1 class="title">
              Find A Great Gift
            </h1>
            <div class='scarlet-highlight-line'></div>
          </div>
          <div class="column is-one-fifth is-hidden-mobile">
          </div>
        </div>
          
      </div>
    </section>
  );
};

export default PrimaryHeader;