import React from "react";
import '../styles/about.css';
import TopNav from "./topnav";
import updateWrapperClass from "../functions/updatewrapperclass.js"

function About() {
  updateWrapperClass();

  return (
    <div>
      {<TopNav />}
      <div class='columns home-columns'>
          <div class='column is-hidden-900'>
            <div class='picture-wrapper'>
              <div class='picture-top'></div>
              <div class='picture-bottom'></div>
            </div>
          </div>

          <div class='column is-three-fifths'>
            <div class='columns'>
              <div class='column is-2 is-hidden-mobile'>
                <div class='subtext-tagline-holder'>
                  <div class='subtext-tagline' title='great GYFTS for great people'></div>
                </div>
              </div>
              <div class='column home-block about-block'>
                  <h1 class='is-hidden'>About GYFTEO</h1>

                  <h2>Giving and receiving gifts is an important part of our relationships.</h2>

                  <p>Despite a seemingly endless choice of things to buy, it is still challenging to find great gifts.</p>

                  <p>GYFTEO is not just about finding any gift. Instead, it makes gift-giving easier than ever by helping you choose gifts that will delight your friends and family.</p>

                  <p>The fun begins by asking you about your intended recipient. Then, GYFTEO shows you cult favorites and top-rated items loved by thousands that you'll be excited to give.</p>

                  <p>&nbsp;</p>

                  <p>Our website is supported by our customers. We sometimes earn affiliate links when you click through the affiliate links on our website.</p>

            </div>
          </div>

        </div>
      </div>
    </div>
  );
};



export default About;