import React from "react";
import '../styles/footer.css';
import {Link} from "react-router-dom";


function Footer () {
  return (
    // Footer
    <footer class="footer">
      <div class="container">
        <div class="columns">

          <div class="column">
            <span title='Email help@gyfteo.com for help'>help@gyfteo.com</span>
            <span>
              &nbsp;&nbsp; | &nbsp;&nbsp;
              <Link to="/about" title='Learn about GYFTEO'>
                About
              </Link>
              &nbsp;&nbsp;&nbsp;
              <a href="https://gyfteo.com/blog" title='Read the GYFTEO blog'>
                Blog
              </a>
            </span>
            <div class='footer-subtitle is-hidden'>
              <span>great<b>gyfts</b></span>
              <span>for great people</span>
            </div>

          </div>
          <div class="column is-hidden-mobile">
          </div>
          <div class="column is-hidden-mobile">
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;