import React from "react";
import {Link} from "react-router-dom";

function ResultsEmpty (props) {
  return (
    // Results Section
    <section class='section-results'>
      <div class="container">
        <div class="columns">
          <div class='column'></div>
          <div class="column is-half has-text-centered">
            
            
          <Link to='/search' title='Back one step'>
            <div title='Return to the filter step' class='no-results-link'>
              <img src='GYFTE-No_Results_Image.jpg' alt='GYFTE-NO Results Found' />
              <br />
              Try Again
            </div>
          </Link>
            
          </div>
          <div class='column'></div>
        </div>
      </div>
    </section>
  );
};

export default ResultsEmpty;