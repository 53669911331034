import React from "react";
import '../styles/results.css';
import Result from "./result";
import LoadMoreButton from "./utilities/loadmorebutton";
import {Link} from "react-router-dom";

function loadMoreResults() {
  document.getElementsByClassName('remaining-results')[0].classList.remove('is-hidden');
  document.getElementsByClassName('load-more-results')[0].classList.add('is-hidden');
}

function Results (props) {

  const firstNineResults = props.searchResults.firstNineResults || [];
  const remainingResults = props.searchResults.remainingResults || []; 

  return (
    // Results Section
    <section class='section-results'>
      <div class="container">
        <div class='has-text-centered is-italic result-count'>

          <Link to='/search' title='Back one step'>
            <span title='Return to the filter step' class='back-link'>Search Again</span>
          </Link>

        </div>
        <div class="columns is-multiline is-mobile">

          {firstNineResults.map((result, index) => (
            <div class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd" key={index}>
              {
                <Result
                result={result}
                index={index} />
              }
            </div>
          ))}

        </div>

        {<LoadMoreButton
          onClick={loadMoreResults.bind(this)}
          hasRemainingResults={remainingResults.length > 1}
        />}

        <div class="columns is-multiline is-mobile remaining-results is-hidden">

          {remainingResults.map((result, index) => (
            <div class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd" key={index}>
              {
                <Result
                result={result}
                index={index} />
              }
            </div>
          ))}

        </div>
      </div>
    </section>
  );
};

export default Results;