import axios from 'axios';

let contentful_space_id = process.env.REACT_APP_SPACE_ID;
let contentful_access_token = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
let contentful_url = 'https://graphql.contentful.com/content/v1/spaces/' + contentful_space_id;


const lookupAnswers = function(setState) {

  let graphql_query =  {
    query: `
      {
        answerCollection{
          items {
            title
            sys {
              id
            }
            qustion {
              questionId
            }
          }
        }
      }
    `
  }

  axios({
      url: contentful_url,
      method: 'post',
      headers: { Authorization: 'Bearer ' + contentful_access_token },
      data: graphql_query
    }).then((result) => {

      let all_answers = result.data.data.answerCollection.items;

      let question_one = [];
      let question_two = [];
      let question_three = [];

      for (var i = all_answers.length - 1; i >= 0; i--) {
        let answer = all_answers[i];
        let question_id = answer.qustion.questionId;

        switch(question_id) {
          case 1:
            question_one.push(
              {
                key:  answer.sys.id,
                answer: answer.title
              }
            );
            break;
          case 2:
            question_two.push(
              {
                key:  answer.sys.id,
                answer: answer.title
              }
            );
            break;
          case 3:
            question_three.push(
              {
                key:  answer.sys.id,
                answer: answer.title
              }
            );
        }

      };

      let answers = {
        question_one,
        question_two,
        question_three
      }

      setState({
        answers: answers
      });


    }).catch(error => {
      console.log(error);
    });

}

export default lookupAnswers;