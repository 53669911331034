import React from "react";

function LoadMoreButton(props) {
  return (
    <div>
    {props.hasRemainingResults &&
      <div class='load-more-results' onClick={props.onClick} title='Load more GYFTS'>Load More</div>
    }
    </div>
  );
};

export default LoadMoreButton;