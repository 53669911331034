import React from "react";
import {Link} from "react-router-dom";

function GoButton (props) {
  return (
    // Go Button
    <section class='section-go-button'>
      <div class="container">
        <div class="columns">

        <div class="column is-hidden-mobile"></div>
          <div class="column has-text-centered go-button-column">

            <Link to='/gyfts' title='Find the GYFTS'>
              <button class="button is-size-3 is-size-4-touch go-button" onClick={props.onClick}>
                <span>GYFTEO</span>
                <span class="icon is-medium">
                  <i class="">-></i>
                </span>
              </button>
            </Link>
          </div>
          <div class="column is-hidden-mobile"></div>

        </div>
      </div>
    </section>
  );
};

export default GoButton;