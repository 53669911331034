import React from "react";
import '../styles/gyfts.css';
import TopNav from "./topnav";
import updateWrapperClass from "../functions/updatewrapperclass.js"

function Gyfts (props) {
  updateWrapperClass();

  return (
    <div>
      {<TopNav />}
      {props.resultsDisplay}
    </div>
  );
};



export default Gyfts;